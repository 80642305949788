<template>
  <div class="content">
    <div class="page_title">
      <span>个人中心</span>
    </div>
    <section>
      <ul>
        <li>
          <label>姓名</label>
          <span>{{ nickname }}</span>
        </li>
        <li>
          <label>联系电话</label>
          <span>{{ tel }}</span>
        </li>
        <li>
          <label>用户名</label>
          <span>{{ name }}</span>
        </li>
      </ul>
      <ul>
        <li @click="changePassword">
          <label>修改密码</label>
          <img src="../../assets/images/right-icon.png" alt>
        </li>
      </ul>

      <div class="exit" @click="isShowConfirmPopup = true">退出登录</div>

    </section>

    <confirm-modal
      :show-popup="isShowConfirmPopup"
      title="确认退出登录？"
      icon="is_exit"
      @closePopup="isShowConfirmPopup = false"
      @confirm="confirmExit"
    />    <!-- 退出登录二次确认 -->
    <!--    <div v-show="changeShow" class="exit-show" @touchmove.prevent @scroll.prevent>-->
    <!--      <div>-->
    <!--        <div>-->
    <!--          <img src="../../assets/images/login/exit.png" alt>确认退出登录？-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          <span @click="cancel">取消</span>-->
    <!--          <span @click="confirm">确定</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import port from '@/api/port'
import cookies from '@/utils/cookie.js'
import ConfirmModal from '@/base/modal/confirmModal'

export default {
  name: 'Personal',
  components: { ConfirmModal },
  data() {
    return {
      name: '',
      tel: '',
      nickname: '',
      isShowConfirmPopup: false // 确认退出
    }
  },
  created() {
    const data = {
      method: '100002',
      token: cookies.get('token')
    }
    port.portAddress(data).then(res => {
      if (res.status == 200) {
        this.name = res.data.username
        this.tel = res.data.iphone
        this.nickname = res.data.nickname
      } else {
        this.$toast({ message: res.msg })
      }
    })
  },
  methods: {
    changePassword() {
      this.$router.push({
        path: '/changeWord'
      })
    },
    confirmExit() {
      const data = {
        method: '100004',
        token: cookies.get('token')
      }
      port.portAddress(data).then(res => {
        if (res.status == 200) {
          this.isShowConfirmPopup = false
          cookies.remove('token')
          this.$router.push({
            path: '/login'
          })
        } else {
          this.$toast({ message: res.msg })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  //position: relative;
  //min-height: 100vh;
  section {
    padding-top: 0.75rem;
  }

  ul{
    margin-bottom: .14rem;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .02rem;
      height: .85rem;
      padding: 0 .27rem;
      background: #fff;
      label{
        font-size: .24rem;
      }
      span{
        font-size: .2rem;
      }
      img{
        width: .34rem;
        height: .34rem;
      }
    }
  }
  .exit{
    width: 100%;
    height: .85rem;
    line-height: .85rem;
    text-align: center;
    color: #2F8AF1;
    background: #fff;
    font-size: .24rem;
  }

}
</style>
